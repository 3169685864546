import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import Layout from 'src/components/Layout'
import { Inner } from 'src/components/layouts/Inner'
import { PageHeading } from 'src/components/molecules/Heading'
import { useSiteMetadata } from 'src/hooks'
import PageMeta from 'src/components/PageMeta'

const ContactComplete: React.FC = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout>
      <PageMeta title={`お問い合わせ完了 | ${siteTitle}`} />
      <Inner>
        <ScPageHeading heading="お問い合わせ" subHeading="Contact" />
        <section>
          <p>お問い合わせ内容の送信が完了しました。</p>
        </section>
      </Inner>
    </Layout>
  )
}

export default ContactComplete

const ScPageHeading = styled(PageHeading)`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`
